export const MINUTE_MS = 60_000
export function getFileImage(file: File) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const img = new Image()
      img.onload = () => {
        resolve(img)
      }
      img.src = reader.result as any
    }
    reader.onerror = (error) => reject(error)
  })
}

export const MINIMUM_IMAGE_SIZE = {
  width: 500,
  height: 500,
}

export function getFileBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const [, base64] = (reader.result as string).split(',')

      resolve(base64 as string)
    }
    reader.onerror = (error) => reject(error)
  })
}

export function saveFile(data: string, ext: string) {
  const downloadLink = document.createElement('a')
  downloadLink.setAttribute('download', 'CanvasAsImage.' + ext)
  downloadLink.setAttribute('href', 'data:application/octet-stream;base64,' + data)
  downloadLink.click()
}

export const GetMonthString = () => {
  const today = new Date()

  // const tt = today.setHours(0, 0, 0, 0)
  today.setDate(1)
  return {
    begin: new Date(today.setHours(0, 0, 0, 0)).toLocaleString('sv-SE').slice(0, 10),
    end: new Date(today.setMonth(today.getMonth() + 1)).toLocaleString('sv-SE').slice(0, 10),
  }
}

export const sleep = async (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export function decimalFloor(value: number, place: number) {
  // value 값을 string 으로 바꾸면 6e-15로 나오는 경우에는 NaN으로 계산되서 이렇게 수정함
  if (Math.abs(value) < Math.pow(0.1, place)) {
    return 0
  }

  return Number(`${Math.floor(`${value}e${place}` as any)}e-${place}`)
}

export function toCurrencyString(value: number) {
  const normalizeValue = decimalFloor(value, 2)

  return normalizeValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function querystring(dict: { [id: string]: string | { toString: () => string } | null }): string {
  return Object.entries(dict)
    .filter(([, value]) => value != null)
    .map(([key, value]) => `${key}=${value?.toString()}`)
    .join('&')
}

export function setUrlSearchParamsValue(searchParams: URLSearchParams, key: string, value: string | null) {
  if (value == null || value === '') {
    searchParams.delete(key)
  } else {
    searchParams.set(key, value)
  }
}

export function makeClearanceParams(params: { [key: string]: string | number | boolean | null | undefined }) {
  const newParams = { ...params }
  for (const key in newParams) {
    const pramsValue = newParams[key]?.toString().trim()
    if (pramsValue === null || pramsValue === undefined) {
      delete newParams[key]
    } else {
      newParams[key] = pramsValue
    }
  }

  return newParams
}
