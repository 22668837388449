import { useState, useEffect, Fragment } from 'react'

import { addDays, format, parse } from 'date-fns'
import {
  Checkbox,
  Collapse,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Box, maxWidth } from '@mui/system'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { koKR } from '@mui/x-date-pickers/locales'

import { getAgents, getAgentUserUsage, getAgentVendorUsage } from '../../api'
import { Agent, StatsAgentUserUsage } from '../../api.interface'
import { toCurrencyString } from '../../util'

console.log(process.env.REACT_APP_API)

const numberFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

function DailyAgentUserUsage() {
  const [rows, setRows] = useState<StatsAgentUserUsage[]>([])
  const [agents, setAgents] = useState<Agent[]>([])
  const [needUpdate, setNeedUpdate] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const agentIdParam = searchParams.get('agent')

  const currentDay = searchParams.get('day')

  console.log('DailyAgentUserUsage ', currentDay, agentIdParam)

  async function fetchData() {
    try {
      if (agentIdParam == null || currentDay == null) {
        return
      }

      const rawData = await getAgentUserUsage({ agentId: agentIdParam, day: currentDay })
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      setRows(rawData.data)
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }
  async function fetchAgents() {
    try {
      const rawData = await getAgents({})
      console.log(rawData)

      if (rawData.data == null) {
        throw 'error packet'
      }

      const agents = rawData.data.map((value) => ({
        ...value,
        level: Number(value.level),
      }))

      const masterAgents = agents.filter((value) => value.level === 0)

      const otherAgents = agents.filter((value) => value.level > 0)

      const filteredAgent = otherAgents.filter((value) => value.status === 'reg')

      const sortedAgent = filteredAgent.sort((a, b) => {
        return a.nick < b.nick ? -1 : a.nick > b.nick ? 1 : 0
      })

      setAgents([...masterAgents, ...sortedAgent])
    } catch (err) {
      console.log(err)
      alert('fail fetch data')
    }
  }

  if (needUpdate) {
    fetchData()
    setNeedUpdate(false)
  }

  useEffect(() => {
    console.log('useEffect')
    fetchData()
    fetchAgents()
  }, [])

  console.log('FakeDailyStatsPage render')

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          minWidth: 800,
          maxWidth: 1500,
          marginLeft: '20px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', alignSelf: 'left' }}>
          <FormControl style={{}}>
            <InputLabel id="demo-simple-select-label">에이전트</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={agentIdParam}
              label="Agent"
              sx={{ width: '200px' }}
              onChange={(event) => {
                searchParams.set('agent', event.target.value as string)
                setSearchParams(searchParams)
                setNeedUpdate(true)
              }}
            >
              {agents.map((value) => (
                <MenuItem key={value.agentId} value={value.agentId}>
                  {`${value.nick} (${value.agentId})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DatePicker
            sx={{ maxWidth: '400px', marginLeft: '10px' }}
            value={currentDay != null ? new Date(currentDay) : null}
            format="yyyy-MM-dd"
            label="날짜"
            onChange={(newValue) => {
              if (newValue != null) {
                searchParams.set('day', format(newValue, 'yyyy-MM-dd'))
                setSearchParams(searchParams)
                setNeedUpdate(true)
              }
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Table sx={{ marginLeft: '10px', minWidth: '700px' }} stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">유저</TableCell>
                <TableCell align="center">베팅머니</TableCell>
                <TableCell align="center">승리머니</TableCell>
                <TableCell align="center">윈루스</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row._id}>
                  <TableCell align="center">{row._id}</TableCell>
                  <TableCell align="center">{toCurrencyString(row.amountBet)}</TableCell>
                  <TableCell align="center">{toCurrencyString(row.amountWin)}</TableCell>
                  <TableCell align="center">{toCurrencyString(row.winlose)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </LocalizationProvider>
  )
}

export default DailyAgentUserUsage
